/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { Fragment, VNode, h } from "preact"
import { Attention, GLOBAL_NOTIFICATION_TIMEOUT as GLOBAL_TOAST_TIMEOUT, Notification, useNotifications } from "../index.browser.js"

/**
 * Toasts should be considered when displaying these types of information to the user:
 * 
 * Low attention messages that do not require user action
 * Singular status updates
 * Confirmations
 * Information that does not need to be followed up
 * 
 * Do not use toasts if the information contains the following:
 * 
 * High attention and crtitical information
 * Time-sensitive information
 * Requires user action or input
 * Batch updates
 * 
 * @returns 
 */
export function ToastBanner(): VNode {
  const notifs = useNotifications()
  if (notifs.length === 0) return <Fragment />
  const show = notifs.filter(e => !e.message.ack && !e.message.timeout)
  if (show.length === 0) return <Fragment />
  return <AttentionByType msg={show[0]} />
}

function AttentionByType({ msg }: { msg: Notification }) {
  switch (msg.message.type) {
    case "error":
      return <Attention type="danger" title={msg.message.title} onClose={() => {
        msg.acknowledge()
      }} timeout={GLOBAL_TOAST_TIMEOUT}>
        {msg.message.description &&
          <div class="mt-2 text-sm text-red-700">
            {msg.message.description}
          </div>
        }
      </Attention>
    case "info":
      return <Attention type="success" title={msg.message.title} onClose={() => {
        msg.acknowledge();
      }} timeout={GLOBAL_TOAST_TIMEOUT} />
  }
}
