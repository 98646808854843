/*
 This file is part of TALER
 (C) 2016 GNUnet e.V.

 TALER is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 TALER is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 TALER; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 * Helpers for doing XMLHttpRequest-s that are based on ES6 promises.
 * Allows for easy mocking for test cases.
 *
 * The API is inspired by the HTML5 fetch API.
 */

/**
 * Imports
 */

import * as impl from "#http-impl";
import * as common from "./http-common.js";

export * from "./http-common.js";

export function createPlatformHttpLib(
  args?: common.HttpLibArgs,
): common.HttpRequestLibrary {
  return new impl.HttpLibImpl(args);
}
