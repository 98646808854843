/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { stringifyTalerUri, TalerUri } from "@gnu-taler/taler-util";
import { ComponentChildren, createContext, h, VNode } from "preact";
import { useContext } from "preact/hooks";

/**
 * https://docs.taler.net/design-documents/039-taler-browser-integration.html
 *
 * @param uri
 */
function createHeadMetaTag(uri: TalerUri, onNotFound?: () => void) {
  const meta = document.createElement("meta");
  meta.setAttribute("name", "taler-uri");
  meta.setAttribute("content", stringifyTalerUri(uri));

  document.head.appendChild(meta);

  let walletFound = false;
  window.addEventListener("beforeunload", () => {
    walletFound = true;
  });
  setTimeout(() => {
    if (!walletFound && onNotFound) {
      onNotFound();
    }
  }, 10); //very short timeout
}
interface Type {
  /**
   * Tell the active wallet that an action is found
   *
   * @param uri
   * @returns
   */
  publishTalerAction: (uri: TalerUri, onNotFound?: () => void) => void;
}

// @ts-expect-error default value to undefined, should it be another thing?
const Context = createContext<Type>(undefined);

export const useTalerWalletIntegrationAPI = (): Type => useContext(Context);

export const TalerWalletIntegrationBrowserProvider = ({
  children,
}: {
  children: ComponentChildren;
}): VNode => {
  const value: Type = {
    publishTalerAction: createHeadMetaTag,
  };
  return h(Context.Provider, {
    value,
    children,
  });
};

export const TalerWalletIntegrationTestingProvider = ({
  children,
  value,
}: {
  children: ComponentChildren;
  value: Type;
}): VNode => {
  return h(Context.Provider, {
    value,
    children,
  });
};
